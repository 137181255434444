@import '~tippy.js/dist/tippy.css';

[data-tippy-root] {
  max-width: 50vw;
}

.tippy-box {
  width: 100%;
  background-color: transparent;
  pointer-events: auto;
}

.tippy-arrow {
  display: none;
}
