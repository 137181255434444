input[type=range] {
  height: 2rem;
  -webkit-appearance: none;
  margin: 0.5rem 0;
  width: 100%;
}
input[type=range]:focus {
  outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 2px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0 0 0 #000000;
  background: #0FDBA8;
  border-radius: 0;
  border: 0 solid #000000;
}
input[type=range]::-webkit-slider-thumb {
  box-shadow: 0 0 0 #000000;
  border: 0 solid #2497E3;
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 24px;
  background: #0FDBA8;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -0.75rem;
}
input[type=range]:focus::-webkit-slider-runnable-track {
  background: #0FDBA8;
}
input[type=range]::-moz-range-track {
  width: 100%;
  height: 2px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0 0 0 #000000;
  background: #0FDBA8;
  border-radius: 0;
  border: 0 solid #000000;
}
input[type=range]::-moz-range-thumb {
  box-shadow: 0 0 0 #000000;
  border: 0 solid #2497E3;
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 1.5rem;
  background: #0FDBA8;
  cursor: pointer;
}
input[type=range]::-ms-track {
  width: 100%;
  height: 2px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type=range]::-ms-fill-lower {
  background: #0FDBA8;
  border: 0 solid #000000;
  border-radius: 0;
  box-shadow: 0 0 0 #000000;
}
input[type=range]::-ms-fill-upper {
  background: #0FDBA8;
  border: 0 solid #000000;
  border-radius: 0;
  box-shadow: 0 0 0 #000000;
}
input[type=range]::-ms-thumb {
  margin-top: 1px;
  box-shadow: 0 0 0 #000000;
  border: 0 solid #2497E3;
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 1.5rem;
  background: #0FDBA8;
  cursor: pointer;
}
input[type=range]:focus::-ms-fill-lower {
  background: #0FDBA8;
}
input[type=range]:focus::-ms-fill-upper {
  background: #0FDBA8;
}
